<template>
  <!-- 头部 -->
  <div>
    <div ref="header" class="headerContainer" v-if="type == 'pc'">
      <div class="header">
        <div class="left">
          <h1 class="logo" @click="goRouter('/')">
            <h1>compax</h1>
            <img src="@/assets/img/logo.png" alt="" />
          </h1>
          <div class="nav">
            <div
              class="item"
              @mouseenter="handleMouseEnter('productLineShow')"
              @mouseleave="handleMouseLeave('productLineShow')"
              @touchstart="handleMouseEnter('productLineShow')"
            >
              <div class="title cursor" :class="{ titleTwo: productLineShow }">
                Products
              </div>
              <transition name="el-zoom-in-top">
                <div class="drop_down_container" v-show="productLineShow">
                  <div class="drop_down_box">
                    <div class="titleNav">
                      <div
                        :class="activate == 1 ? 'activateTitle' : 'titles'"
                        class="cursor"
                        @mouseenter="handleMouseEnter('desktopShow')"
                      >
                        Desktop Terminals
                      </div>
                      <div
                        :class="activate == 2 ? 'activateTitle' : 'titles'"
                        class="cursor"
                        @mouseenter="handleMouseEnter('smartWeighingShow')"
                      >
                        Smart Weighing
                      </div>
                      <div
                        :class="activate == 3 ? 'activateTitle' : 'titles'"
                        class="cursor"
                        @mouseenter="handleMouseEnter('kiosksShow')"
                      >
                        Kiosks
                      </div>
                      <div
                        :class="activate == 4 ? 'activateTitle' : 'titles'"
                        class="cursor"
                        @mouseenter="handleMouseEnter('Peripherals')"
                      >
                        Peripherals
                      </div>
                    </div>
                    <div class="desktopShow" v-show="activate == 1">
                      <div class="itemOne" @click="goRouter('/ACE1')">
                        <div class="img"></div>
                        <div class="bottom">
                          <div class="title">ACE 1</div>
                          <div class="text">Smart Desktop</div>
                          <div class="text">Terminal</div>
                        </div>
                      </div>
                      <div class="itemTwo" @click="goRouter('/ACE1Pro')">
                        <div class="img"></div>
                        <div class="bottom">
                          <div class="title">ACE 1 Pro</div>
                          <div class="text">Smart Desktop</div>
                          <div class="text">Terminal</div>
                        </div>
                      </div>
                      <div class="itemThree" @click="goRouter('ACE2')">
                        <div class="img"></div>
                        <div class="bottom">
                          <div class="title">ACE 2</div>
                          <div class="text">Smart Desktop</div>
                          <div class="text">Terminal</div>
                        </div>
                      </div>
                      <div class="itemFour" @click="goRouter('/D1')">
                        <div class="img"></div>
                        <div class="bottom">
                          <div class="title">D1</div>
                          <div class="text">Smart Desktop</div>
                          <div class="text">Terminal</div>
                        </div>
                      </div>
                      <div class="itemFive" @click="goRouter('/Y1')">
                        <div class="img"></div>
                        <div class="bottom">
                          <div class="title">Y1</div>
                          <div class="text">Smart Desktop</div>
                          <div class="text">Terminal</div>
                        </div>
                      </div>
                    </div>
                    <div class="smartWeighingShow" v-show="activate == 2">
                      <div class="itemOne" @click="goRouter('/ET7')">
                        <div class="img"></div>
                        <div class="bottom">
                          <div class="title">ET7</div>
                          <div class="text">Smart Weighing</div>
                          <div class="text">Terminal</div>
                        </div>
                      </div>
                    </div>
                    <div class="kiosksShow" v-show="activate == 3">
                      <div class="itemOne" @click="goRouter('/MK215')">
                        <div class="img"></div>
                        <div class="bottom">
                          <div class="title">MK 215</div>
                          <div class="text">Smart Kiosk</div>
                          <div class="text">Terminal</div>
                        </div>
                      </div>
                    </div>
                    <div class="accessoriesShow" v-show="activate == 4">
                      <div class="itemOne" @click="goRouter('/9520Series')">
                        <div class="img"></div>
                        <div class="bottom">
                          <div class="title">Agility 9520 Series</div>
                          <div class="text">2D Barcode</div>
                          <div class="text">Scanner</div>
                        </div>
                      </div>
                      <div class="itemTwo" @click="goRouter('/9533Series')">
                        <div class="img"></div>
                        <div class="bottom">
                          <div class="title">Agility 9533 Series</div>
                          <div class="text">2D Wireless Barcode</div>
                          <div class="text">Scanner</div>
                        </div>
                      </div>
                      <div class="itemThree" @click="goRouter('/A90')">
                        <div class="img"></div>
                        <div class="bottom">
                          <div class="title">Agility A-90</div>
                          <div class="text">2D Desktop Barcode</div>
                          <div class="text">Scanner</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="footer">
                    <div class="textBox" @click="goRouter('/desktopTerminals')">
                      More Products <i class="el-icon-d-arrow-right ico"></i>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <div class="item">
              <div class="title cursor" @click="goRouter('/PlatformServices')">
                Platform Services
              </div>
            </div>
            <div class="item">
              <div class="title cursor" @click="goRouter('/customerCases')">
                Customer Cases
              </div>
            </div>
            <div class="item" @click="goRouter('/contactUs')">
              <div class="title cursor">Contact Us</div>
            </div>
            <div class="item" @click="goRouter('/contactUs')">
              <a href="https://docs.compax.cc" target="_blank">
                <div class="title">Developer Center</div>
              </a>
            </div>
            <div class="right">
              <a
                href="https://dms.compax.cc/#/"
                target="_bla
          nk"
              >
                <div class="loginBtn">DMS Login</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="header" class="headerContainerPhone" v-else>
      <div class="header">
        <h1 class="logo" @click="goRouter('/')">
          <h1>compax</h1>
          <img src="@/assets/img/logo.png" alt="" />
        </h1>
        <div class="bthIcon el-icon-s-fold" @click="handMenuBtn"></div>
      </div>
    </div>

    <!-- 移动端使用的menu导航 -->
    <el-drawer
      size="100vw"
      custom-class="drawer"
      :visible.sync="menuShow"
      direction="rtl"
      :with-header="false"
    >
      <div class="header">
        <h1 class="logo" @click="goRouter('/')">
          <h1>compax</h1>
          <img src="@/assets/img/logo.png" alt="" />
        </h1>
        <div class="bthIcon el-icon-close" @click="handMenuBtn"></div>
      </div>
      <div class="body">
        <div class="item" v-for="(item, index) in PhoneMenuData" :key="index">
          <div
            class="title"
            :class="{ activate: item.show }"
            @click="item.show = !item.show"
          >
            <h1>{{ item.label }}</h1>
            <div
              class="icon"
              :class="!item.show ? 'el-icon-plus' : 'el-icon-minus'"
            ></div>
          </div>
          <ul v-show="item.show">
            <li
              v-for="(children, indexs) in item.children"
              :key="indexs"
              @click="goRouter(children.route.path, children.route.anchor)"
            >
              {{ children.label }}
            </li>
          </ul>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { EventBus } from '@/utils/eventBus.js'
export default {
  mounted() {
    localStorage.setItem('header', this.$refs.header.offsetHeight)
  },
  data() {
    return {
      menuShow: false,
      type: 'pc',
      lang: '' || this.$i18n.locale, //语言标识
      productLineShow: false, //产品系列下拉框是否显示,
      activate: 1, //1为Desktop Terminals 2为Kiosks
      PhoneMenuData: [
        {
          show: true,
          label: 'Products',
          children: [
            {
              label: 'Desktop Terminals',
              route: { path: '/desktopTerminals', anchor: 'desktopTerminals' }
            },
            {
              label: 'Smart Weighing',
              route: { path: '/desktopTerminals', anchor: 'smartWeighing' }
            },
            {
              label: 'Kiosks',
              route: { path: '/desktopTerminals', anchor: 'Kiosks' }
            },
            {
              label: 'Peripherals',
              route: { path: '/desktopTerminals', anchor: 'Peripherals' }
            }
          ]
        },
        {
          show: false,
          label: 'Platform Services',
          children: [
            { label: 'DMS', route: { path: '/PlatformServices', anchor: '' } }
          ]
        },
        {
          show: false,
          label: 'Customer Cases',
          children: [
            {
              label: 'Shopping Center',
              route: { path: '/customerCases', anchor: 'ShoppingCenter' }
            },
            {
              label: 'F&B',
              route: { path: '/customerCases', anchor: 'FB' }
            },
            {
              label: 'Retails',
              route: { path: '/customerCases', anchor: 'Retails' }
            },
            {
              label: 'Supermarket',
              route: { path: '/customerCases', anchor: 'Supermarket' }
            }
          ]
        },
        {
          show: false,
          label: 'Contact Us',
          children: [
            {
              label: 'About Us',
              route: { path: '/contactUs', anchor: 'contactUs' }
            },
            {
              label: 'Inquiry',
              route: { path: '/contactUs', anchor: 'Inquiry' }
            }
          ]
        }
      ]
    }
  },
  watch: {
    '$i18n.locale'(val) {
      this.languageFn(val)
    }
  },
  created() {
    this.type = localStorage.getItem('setRem')
  },
  methods: {
    handMenuBtn() {
      this.menuShow = !this.menuShow
    },
    //鼠标移入
    handleMouseEnter(val) {
      if (val === 'productLineShow') {
        this.productLineShow = true
        return
      }
      if (val === 'desktopShow') {
        this.activate = 1
        return
      }
      if (val === 'smartWeighingShow') {
        this.activate = 2
        return
      }
      if (val === 'kiosksShow') {
        this.activate = 3
        return
      }
      if (val === 'Peripherals') {
        this.activate = 4
        return
      }
    },
    //鼠标移出去
    handleMouseLeave(val) {
      if (val === 'productLineShow') {
        this.productLineShow = false
        this.activate = 1
      }
    },
    //跳转页面
    async goRouter(val, anchor) {
      if (!val) return
      if (anchor) {
        await this.$router.push({ path: val })
        EventBus.$emit(val, anchor)
      } else {
        this.$router.push({ path: val })
      }
      this.productLineShow = false
      this.menuShow = false
    }
  }
}
</script>

<style lang="less" scoped>
.bthIcon {
  color: #fff;
  position: absolute;
  bottom: 13px;
  right: 23px;
  font-size: 24px;
}
.headerContainer {
  height: 80px;
  z-index: 88;
  width: 100%;
  background: rgba(21, 21, 22, 1);
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: center;
  .header {
    .cursor {
      cursor: pointer;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    .left {
      height: 100%;
      display: flex;
      align-items: center;
      .logo {
        margin: 10px auto;
        cursor: pointer;
        position: relative;
        margin-right: 30px;
        width: 207.13px;
        height: 40px;
        overflow: hidden;
        h1 {
          position: absolute;
          left: -9999px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .nav {
        height: 100%;
        display: flex;
        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          // .title {
          //   color: #fff;
          //   text-shadow: 1px 1px 1px #666;
          //   line-height: 80px;
          //   text-align: center;
          //   padding: 0px 40px;
          // }
          .title {
            color: #fff;
            text-shadow: 1px 1px 1px #666;
            line-height: 80px;
            text-align: center;
            padding: 0px 32.5px;
          }
          .noTitle {
            color: #fff;
            text-shadow: 1px 1px 1px #666;
            line-height: 80px;
            text-align: center;
            padding: 0px 40px;
          }
          .titleTwo {
            background: rgba(255, 255, 255, 0.5);
          }
          @media (any-hover: hover) {
            &:hover .title {
              text-shadow: none;
              color: #344f98;
              //font-weight: 700;
            }
          }
          .drop_down_container {
            z-index: 999;
            position: absolute;
            top: 80px;
            left: 0px;
            width: 100%;
            background: #fff;
            .drop_down_box {
              padding-top: 48px;
              height: 420px;
              margin: 0px auto;
              // display: flex;
              .titleNav {
                height: fit-content;
                margin: 0px auto;
                width: 1009px;
                display: flex;
                justify-content: space-between;
                .titles {
                  height: fit-content;
                  color: rgba(56, 56, 56, 1);
                  font-size: 20px;
                  font-weight: 400;
                  line-height: 28.96px;
                }
                .activateTitle {
                  height: fit-content;
                  color: rgba(0, 92, 255, 1);
                  font-weight: 700;
                  font-size: 20px;
                  line-height: 28.96px;
                }
              }
              .desktopShow {
                margin: 0px auto;
                width: 1200px;
                margin-top: 57px;
                display: flex;
                justify-content: space-between;
                .bottom {
                  height: 76px;
                  .title {
                    text-align: center;
                    margin: 0px 0px 6px;
                    color: rgba(0, 92, 255, 1);
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 28.96px;
                  }
                  .text {
                    text-align: center;
                    color: rgba(166, 166, 166, 1);
                    font-size: 14px;
                    line-height: 20.27px;
                    font-weight: 500;
                  }
                }
                .itemOne {
                  width: 223.37px;
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: center;
                  .img {
                    transition: all 0.3s;
                    height: 124.62px;
                    width: 223.37px;
                    background: url('../../assets/下拉栏配图/ACE 1/透明底.png')
                      no-repeat center;
                    background-size: cover;
                    &:hover {
                      transform: scale(1.05);
                      background: url('../../assets/下拉栏配图/ACE 1/透明底2.png')
                        no-repeat center;
                      background-size: cover;
                    }
                  }
                }
                .itemTwo {
                  width: 223.37px;
                  align-items: center;
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  .img {
                    transition: all 0.3s;
                    height: 132.73px;
                    width: 122.32px;
                    background: url('../../assets/下拉栏配图/ACE 1 Pro/透明底.png')
                      no-repeat center;
                    background-size: cover;
                    &:hover {
                      transform: scale(1.05);
                      background: url('../../assets/下拉栏配图/ACE 1 Pro/透明底2.png')
                        no-repeat center;
                      background-size: cover;
                    }
                  }
                }
                .itemThree {
                  width: 223.37px;
                  align-items: center;
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  .img {
                    transition: all 0.3s;
                    width: 163.83px;
                    height: 123.44px;
                    background: url('../../assets/下拉栏配图/ACE 2/2.png')
                      no-repeat center;
                    background-size: cover;
                    &:hover {
                      transform: scale(1.05);
                      background: url('../../assets/下拉栏配图/ACE 2/1.png')
                        no-repeat center;
                      background-size: cover;
                    }
                  }
                }
                .itemFour {
                  width: 223.37px;
                  align-items: center;
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  .img {
                    transition: all 0.3s;
                    width: 135.27px;
                    height: 128.86px;
                    background: url('../../assets/下拉栏配图/D1/2.png')
                      no-repeat center;
                    background-size: cover;
                    &:hover {
                      transform: scale(1.05);
                      background: url('../../assets/下拉栏配图/D1/1.png')
                        no-repeat center;
                      background-size: cover;
                    }
                  }
                }
                .itemFive {
                  width: 223.37px;
                  align-items: center;
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  .img {
                    transition: all 0.3s;
                    height: 130.9px;
                    width: 140.03px;
                    background: url('../../assets/下拉栏配图/Y1/2.png')
                      no-repeat center;
                    background-size: cover;
                    &:hover {
                      transform: scale(1.05);
                      background: url('../../assets/下拉栏配图/Y1/1.png')
                        no-repeat center;
                      background-size: cover;
                    }
                  }
                }
              }
              .smartWeighingShow {
                margin: 0px auto;
                width: 1500px;
                margin-top: 30px;
                display: flex;
                justify-content: center;
                .bottom {
                  height: 76px;
                  .title {
                    text-align: center;
                    margin: 0px 0px 6px;
                    color: rgba(0, 92, 255, 1);
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 28.96px;
                  }
                  .text {
                    text-align: center;
                    color: rgba(166, 166, 166, 1);
                    font-size: 14px;
                    line-height: 20.27px;
                    font-weight: 500;
                  }
                }
                .itemOne {
                  width: 223.37px;
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: space-between;
                  .img {
                    margin-top: 30px;
                    transition: all 0.3s;
                    height: 132.64px;
                    width: 98.92px;
                    background: url('../../assets/下拉栏配图/ET 7/2.png')
                      no-repeat center;
                    background-size: cover;
                    &:hover {
                      transform: scale(1.05);
                      background: url('../../assets/下拉栏配图/ET 7/3.png')
                        no-repeat center;
                      background-size: cover;
                    }
                  }
                }
              }
              .kiosksShow {
                margin: 0px auto;
                width: 1500px;
                margin-top: 30px;
                display: flex;
                justify-content: center;
                .bottom {
                  height: 76px;
                  .title {
                    text-align: center;
                    margin: 0px 0px 6px;
                    color: rgba(0, 92, 255, 1);
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 28.96px;
                  }
                  .text {
                    text-align: center;
                    color: rgba(166, 166, 166, 1);
                    font-size: 14px;
                    line-height: 20.27px;
                    font-weight: 500;
                  }
                }
                .itemOne {
                  width: 223.37px;
                  height: 266px;
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: center;
                  .img {
                    transition: all 0.3s;
                    height: 168px;
                    width: 194px;
                    background: url('../../assets/下拉栏配图/MK 215/默认变量.png')
                      no-repeat center;
                    background-size: cover;
                    &:hover {
                      transform: scale(1.05);
                      background: url('../../assets/下拉栏配图/MK 215/变量 2.png')
                        no-repeat center;
                      background-size: cover;
                    }
                  }
                }
              }
              .accessoriesShow {
                margin: 0px auto;
                width: 780px;
                margin-top: 57px;
                display: flex;
                justify-content: space-between;
                .bottom {
                  height: 76px;
                  .title {
                    text-align: center;
                    margin: 0px 0px 6px;
                    color: rgba(0, 92, 255, 1);
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 28.96px;
                  }
                  .text {
                    text-align: center;
                    color: rgba(166, 166, 166, 1);
                    font-size: 14px;
                    line-height: 20.27px;
                    font-weight: 500;
                  }
                }
                .itemOne {
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: center;
                  .img {
                    transition: all 0.3s;
                    height: 157px;
                    width: 157px;
                    background: url('../../assets/下拉栏配图/Agility9520Series/正面.png.png')
                      no-repeat center;
                    background-size: cover;
                    &:hover {
                      transform: scale(1.05);
                      background-size: cover;
                    }
                  }
                }
                .itemTwo {
                  align-items: center;
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  .img {
                    transition: all 0.3s;
                    height: 165px;
                    width: 165px;
                    background: url('../../assets/下拉栏配图/Agility9533Series/下拉栏配图.png')
                      no-repeat center;
                    background-size: cover;
                    &:hover {
                      transform: scale(1.05);
                      // background: url('../../assets/下拉栏配图/ACE 1 Pro/透明底2.png')
                      //   no-repeat center;
                      background-size: cover;
                    }
                  }
                }
                .itemThree {
                  width: 220px;
                  align-items: center;
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  .img {
                    transition: all 0.3s;
                    height: 165px;
                    width: 165px;
                    background: url('../../assets/下拉栏配图/A90/下拉框.png')
                      no-repeat center;
                    background-size: cover;
                    &:hover {
                      transform: scale(1.05);
                      // background: url('../../assets/下拉栏配图/ACE 1 Pro/透明底2.png')
                      //   no-repeat center;
                      background-size: cover;
                    }
                  }
                }
              }
            }
            .footer {
              border: 2px solid rgba(229, 229, 229, 1);
              display: flex;
              align-items: center;
              justify-content: center;
              color: rgba(0, 92, 255, 1);
              height: 70px;
              font-size: 20px;
              font-weight: 500;
              line-height: 28.96px;
              .textBox {
                cursor: pointer;
                display: flex;
                align-items: center;
                i {
                  display: none;
                }
                .ico {
                  font-weight: 500;
                  font-size: 20px;
                }
                &:hover i {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    .right {
      margin-left: 30px;
      display: flex;
      align-items: center;
      .loginBtn {
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 92, 255, 1);
        width: 152px;
        height: 50px;
        border-radius: 20px;
      }
    }
  }
}
.headerContainerPhone {
  border-bottom: 2px solid #005cff;
  color: #fff;
  height: 95px;
  z-index: 88;
  width: 100%;
  background: rgba(21, 21, 22, 1);
  position: fixed;
  top: 0px;
  .header {
    width: 100%;
    height: 100%;
    position: relative;
    .logo {
      cursor: pointer;
      position: absolute;
      top: 45px;
      left: 50%;
      transform: translateX(-45%);
      margin-right: 60px;
      width: 167px;
      height: 35px;
      overflow: hidden;
      h1 {
        position: absolute;
        left: -9999px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .menuBtn {
      width: fit-content;
      font-size: 12px;
      position: fix;
      right: 23px;
      bottom: 22px;
    }
  }
}
.drawer {
  width: 100vw;
  .header {
    position: relative;
    height: 95px;
    width: 100%;
    background: #000;
    .logo {
      cursor: pointer;
      position: absolute;
      top: 45px;
      left: 50%;
      transform: translateX(-50%);
      margin-right: 60px;
      width: 167px;
      height: 35px;
      overflow: hidden;
      h1 {
        position: absolute;
        left: -9999px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .body {
    padding-bottom: 99px;
    .item {
      margin: 40px auto 0px;
      width: 334.5px;
      .title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(229, 229, 229, 1);
        font-size: 20px;
        font-weight: 700;
        line-height: 23.17px;
        color: rgba(128, 128, 128, 1);
        .icon {
          color: rgba(128, 128, 128, 1);
          font-size: 16px;
        }
      }
      .activate {
        color: rgba(0, 92, 255, 1);
      }
      li {
        margin-top: 30px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(128, 128, 128, 1);
      }
    }
  }
}
</style>

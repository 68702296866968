<template>
  <div id="app" :class="type == 'pc' ? 'pcPadding' : 'phonePadding'">
    <!-- 头部 -->
    <!-- <PcHeader v-if="$route.path !== '/desktopTerminals'"></PcHeader> -->
    <pcHomeHeader></pcHomeHeader>
    <router-view></router-view>
    <!-- 底部 -->
    <PcFooter></PcFooter>
    <cookiesDialog></cookiesDialog>
  </div>
</template>

<script>
import { setRem } from '@/utils/rem'
import pcHomeHeader from './components/pcHomeHeader'
// import PcHeader from './components/pcHeader/index'
import PcFooter from './components/pcFooter/index'
import cookiesDialog from './components/cookiesDialog/index'
export default {
  components: {
    cookiesDialog,
    pcHomeHeader,
    // PcHeader
    PcFooter
  },
  data() {
    return {
      type: 'pc'
    }
  },
  mounted() {
    this.type = localStorage.getItem('setRem')
    window.addEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.type = localStorage.getItem('setRem')
      if (this.type == 'pc') {
        setRem('pc')
      } else {
        setRem('phone')
      }
    }
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="less" scoped>
#app {
  // margin: 0px auto;
  // position: relative;
  // width: 1366px;
}
.pcPadding {
  padding-top: 80px;
}
.phonePadding {
  padding-top: 95px;
}
</style>

/******** src/utils/rem.js ********/
const baseSize = 16

// 设置 rem 函数
export function setRem(mode) {
  const uiWidth = mode == 'pc' ? 1920 : 390
  // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
  const scale = document.documentElement.clientWidth / uiWidth
  // 设置页面根节点字体大小

  document.documentElement.style.fontSize = baseSize * Math.min(scale, 4) + 'px'
}

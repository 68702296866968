<template>
  <div
    :class="type == 'pc' ? 'cookiesDialogPC' : 'cookiesDialogPhone'"
    v-if="show"
  >
    <div class="title">Please set your cookie preferences</div>
    <div class="content">
      <div class="text">Welcome to compax.cc!</div>
      <div class="text">
        We would like to use cookies on the website to ensure the security,
        efficient operation and service optimization of the website.
      </div>
    </div>
    <div class="button">
      <button @click="cookiesBth('yes')">Accept Cookies</button>
      <button @click="cookiesBth('no')">Reject Cookies</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: 'pc',
      show: false
    }
  },
  created() {
    this.type = localStorage.getItem('setRem')
  },
  methods: {
    cookiesBth(val) {
      localStorage.setItem('acceptCookie', val)
      this.show = false
    }
  },
  watch: {
    $route() {
      let acceptCookie = localStorage.getItem('acceptCookie')
      if (!acceptCookie) {
        this.show = true
      } else {
        this.show = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.cookiesDialogPC {
  padding: 20px 100px;
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  background: rgba(0, 0, 0, 0.7);
  .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 60px;
    color: #fff;
  }
  .content {
    .text {
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      color: #fff;
    }
  }
  .button {
    display: flex;
    justify-content: end;
    button {
      cursor: pointer;
      font-size: 18px;
      margin-right: 30px;
      padding: 15px;
    }
  }
}
.cookiesDialogPhone {
  padding: 20px 20px;
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 180px;
  background: rgba(0, 0, 0, 0.7);
  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 23.17px;
    color: #fff;
  }
  .content {
    .text {
      font-size: 12px;
      font-weight: 500;
      line-height: 17.38px;
      color: #fff;
    }
  }
  .button {
    display: flex;
    justify-content: end;
    button {
      width: 100px;
      height: 40px;
      cursor: pointer;
      font-size: 12px;
      margin-right: 30px;
      padding: 2px;
    }
  }
}
</style>
